import React from 'react';
import {subDays, format} from 'date-fns'
import { ActionAnimations, SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import SwipeDeleteButton from './SwipeDeleteButton/SwipeDeleteButton'
import HistoryTransactionRow from "./HistoryRow/HistoryTransactionRow"
import HistoryDateRow from "./HistoryRow/HistoryDateRow"

import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import './History.scss'

const transitionTimeout = 1500;

export default function (props) {
	let now = Date.now()

	const {recentTransactions, onDeleteTransaction} = props

	let els = []
	if (recentTransactions) {
		// calc the daily expenses
		let dailyBalances = {}
		recentTransactions.forEach(doc => {
			let dateKey = format(new Date(doc.eventTime), "yyyy-MM-dd")

			if ( !(dateKey in dailyBalances)) {
				dailyBalances[dateKey] = {income:0, expense:0}
			}

			dailyBalances[dateKey][doc.localPrice>0 ? "income":"expense"] += doc.localPrice
		})

		// prepare date and transactions row in an array
		let prevDate = null
		recentTransactions.forEach(doc => {
			let d = new Date(doc.eventTime)
			let dateKey = format(d, "yyyy-MM-dd")

			// date changed, show a date row
			if (!prevDate || format(prevDate, "yyyy-MM-dd")!==dateKey) {
				els.push(
					<CSSTransition
						classNames="date-row"
						key={dateKey}
						timeout={transitionTimeout}
					>
						<HistoryDateRow
							key={dateKey}
							eventTime={d}
							income={dailyBalances[dateKey].income}
							expense={dailyBalances[dateKey].expense} />
					</CSSTransition>
				)
				prevDate = d
			}

			// append the transaction row
			els.push(
				<CSSTransition
					classNames="transaction-row"
					key={doc._key}
					timeout={transitionTimeout}
				>
					<SwipeableListItem
						key={doc._key}
						swipeLeft={{
							content: <SwipeDeleteButton />,
							action: () => {onDeleteTransaction(doc._key)},
							actionAnimation: ActionAnimations.REMOVE
						}} >
							<HistoryTransactionRow
								key={doc._key}
								itemName={doc.itemName}
								bucketName={doc.bucket.name}
								bucketKey={doc.bucket._key}
								balance={doc.localPrice}/>
					</SwipeableListItem>
				</CSSTransition>
			)
		})
	}

	return (
		<section className="history">
			<div className="container">
				<h2 className="title is-size-4 has-text-centered">明細</h2>
				<SwipeableList>
					{() => (
						<TransitionGroup
							className=""
							enter={false}
							exit={true}
						>
							{els}
						</TransitionGroup>
					)}
				</SwipeableList>
			</div>
		</section>
	);
}
