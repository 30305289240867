import React from 'react';
import "./index.scss"
import BucketDot from "components/BucketDot"
import MoneySpan from "components/MoneySpan"

import {useSpring, animated} from 'react-spring'

export default function (props) {
	const {name, balance, income, expense, bucketKey} = props

	return (
		<div className="jar-overview-row">

			<div className="row-1 is-flex">
				<div className="dot-wrapper is-flex">
					<BucketDot bucketKey={bucketKey}/>
				</div>

				<h3 className="bucket-name">{name}</h3>
				<div className="num-part">
					<div className="balance-num"><MoneySpan value={balance} showPlus={true}/></div>

					{income!=0 && expense!=0 && (
						<div className="explain-text">
							<MoneySpan value={income} showPlus={true}/>/
							<MoneySpan value={expense}/>
						</div>)}
				</div>
			</div>
		</div>
	);
}
