import React from 'react';
import "./index.scss"
import cx from "classnames"


export default function (props) {
	let {bucketKey} = props
	bucketKey = bucketKey || ""

	return (
		<div className={cx("bucket-dot", bucketKey.toLowerCase())}></div>
	);
}
