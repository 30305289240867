import React from 'react';
import './index.scss';

export default function (props) {
	const {loading} = props

	if (loading) {
		return (
			<div className="loading">
				<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
			</div>
		)
	} else {
		return (<div></div>)
	}
}