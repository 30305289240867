import React from 'react';
import cx from 'classnames'
import "./index.scss"

export default function (props) {
	const {value, showPlus, className} = props

	let isPositive = false
	if (typeof value==="number" && value>0) {
		isPositive = true
	}

	return (
		<span className={cx("money-span", className, {
			'is-positive': isPositive
		})}>
			{showPlus && isPositive && "+"}
			{typeof value==="number" ? value.toLocaleString():value}
		</span>
	);
}
