import { useState, useEffect } from 'react';

import {
  useParams,
  useRouteMatch
} from "react-router-dom";


/**
 * Use this function to generate the auth header which should be append in every requests
 * @return {object} {user-id, user-cipher}
 */
export default function () {
  let params = useParams();
  let match = useRouteMatch();

  return [params.userId, {
    "user-id": params.userId,
    "user-cipher": params.cipher
  }]
}