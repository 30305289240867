import React from 'react';

import "./StatisticPage.scss"

import History from "./History/History"
import ListOfJars from "./ListOfJars/ListOfJars"
import DateRangeSelector from "./DateRangeSelector/DateRangeSelector"

import BasicPage from "components/Layout/BasicPage.js"

import useStore from "../useStore"


export default function () {
	const {loading, 
		userId, 
		bucketBalances, 
		recentTransactions, 
		errorMsg,
		startDate,
		endDate,
		setStartDate,
		setEndDate,
		deleteTransaction,
	} = useStore()

	const onDateRangeChange = (startDate, endDate) => {
		setStartDate(startDate)
		setEndDate(endDate)
	}

	return (
		<BasicPage className="statistic-page">
			{errorMsg && <article class="message is-danger">
				<div class="message-body">
					{errorMsg}
				</div>
			</article>}

			{loading && <div className="loading">
				<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
			</div>}

			<div className="date-range-selector-wrapper">
				<div>統計期間：</div>
				<DateRangeSelector startDate={startDate} endDate={endDate} onChange={onDateRangeChange}/>	
			</div>
			
			<ListOfJars bucketBalances={bucketBalances} />
			<History recentTransactions={recentTransactions} onDeleteTransaction={deleteTransaction}/>
		</BasicPage>
	);
}
