import React from 'react';
import "./HistoryRow.scss"
import cx from "classnames"
import BucketDot from "components/BucketDot"
import MoneySpan from "components/MoneySpan"

export default function (props) {
	const {itemName, bucketName, bucketKey} = props

	let balance = parseInt(props.balance, 10)
	if (isNaN(balance)) {
		balance = 0
	}

	return (
		<div className="history-row">
			<div className="indicator">
				<div className="vertical-line top"></div>
				<BucketDot bucketKey={bucketKey}/>
				<div className="vertical-line bottom"></div>
			</div>
			<div className="main-text">
				<div className="item-name has-text-black is-size-6">
					{itemName}
				</div>
				<div className="bucket-name has-text-grey is-size-7">
					{bucketName}
				</div>
			</div>
			<div className="balance is-flex">
				<MoneySpan
					className={cx("value is-size-6", {
						"income": balance>0
					})}
					value={balance}
					showPlus={true}
				/>
			</div>
		</div>
	);
}