import React from 'react';
import "./DateRangeSelector.scss"
import {startOfMonth, endOfMonth, format, parse, subMonths} from 'date-fns'

export default function (props) {
  const {startDate, endDate, onChange} = props

  let now = new Date()

  let startDateObj = startDate ?
    startOfMonth(parse(startDate, "yyyy-MM-dd", 0)):
    startOfMonth(now)


  const handleChange = (e) => {
    let dObj = parse(e.target.value, "yyyy/MM", 0)

    if (dObj.getFullYear()===1970) {
      onChange(format(new Date(0), "yyyy-MM-dd"), format(endOfMonth(now), "yyyy-MM-dd"))
    } else {
      onChange(format(startOfMonth(dObj), "yyyy-MM-dd"), format(endOfMonth(dObj), "yyyy-MM-dd"))
    }
  }

  return (
    <div className="date-range-select ">
      <select className="select" value={format(startDateObj, "yyyy/MM")} onChange={handleChange}>
        <option value={format(new Date(0), "yyyy/MM")}>所有</option>
        {
          [...Array(25).keys()].reverse().map(i => {
            return (
              <option key={i} value={format(subMonths(now, i), "yyyy/MM")}>{format(subMonths(now, i), "yyyy/MM")}</option>
            )
          })
        }
      </select>
    </div>
  );
}
