import { useState, useEffect } from 'react';
import useAuthHeader from 'components/useAuthHeader'

export default function useStore() {
	const [userId, authHeader] = useAuthHeader()
	const [loading, setLoading] = useState(true)
	const [errorMsg, setErrorMsg] = useState()

	const [defaultBuckets, setDefaultBuckets] = useState()
	const [lastUsedBuckets, setLastUsedBuckets] = useState()


	useEffect(() => {
		if ( !userId) {
			return
		}

		setLoading(true)

		fetch("/api/distribute/getLastRatio", {
			headers: Object.assign({}, authHeader),
		})
		.then(response => response.json())
		.then(response => {

			setLoading(false)
			if (response.status==="OK") {
				setDefaultBuckets(response.defaultBuckets)
				setLastUsedBuckets(response.lastUsedBuckets)

				// setBucketBalances(response.bucketBalances)
				// console.log("setBucketBalances", (response.bucketBalances))
				// setRecentTransactions(response.transactions)
			} else {
				throw new Error("Server error")
			}
		})
		.catch(() => {
			setLoading(false)
			setErrorMsg("Auth Failed Or Server error")
		})
	}, [userId])

	// function to submit the local justiied incomes
	const submitIncomeAndBucketRatios = (income, localBuckets) => {
		setLoading(true)

		return fetch("/api/distribute", {
			method: 'POST',
			headers: Object.assign({
				'Content-Type': 'application/json'
			}, authHeader),
			body: JSON.stringify({
				income: income,
				localBuckets: localBuckets
			})
		})
		.then(response => response.json())
		.then(response => {
			setLoading(false)

			if (response.status==="OK") {
				return response
			} else {
				throw new Error("Server Error")
			}
		})
		.catch(error => {
			console.error(error)
			setLoading(false)
			throw error
		})
	}

	return {loading, userId, errorMsg,
		defaultBuckets,
		lastUsedBuckets,
		submitIncomeAndBucketRatios};
}