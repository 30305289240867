import React from 'react';
import './App.scss';

import StatisticPage from './StatisticPage/StatisticPage'
import DistributePage from './DistributePage/DistributePage'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";

function App() {
	return (
		<Router>
			<div className="app">
				<Switch>
					<Route path="/users/:userId/:cipher/distribute/">
						<DistributePage />
					</Route>

					<Route path="/users/:userId/:cipher/">
						<StatisticPage />
					</Route>

				</Switch>
			</div>
		</Router>
	);
}

export default App;
