import React from 'react';
import JarOverviewRow from "./JarOverviewRow"
import "./ListOfJars.scss"

export default function (props) {
	const {bucketBalances} = props

	return (
		<section className="list-of-jars">
			<div className="container">
				<h2 className="title is-size-4 has-text-centered">罐子結餘</h2>

				{bucketBalances && bucketBalances.map(doc => {
					return (
						<JarOverviewRow
							{...doc.bucket}
							key={doc.bucket._key}
							bucketKey={doc.bucket._key} />)
				})}

				{!bucketBalances && [
					<JarOverviewRow key="FINANCIAL" bucketName="財富自由帳戶" bucketKey="financial" balance={"-"}/>,
					<JarOverviewRow key="EDUCATION" bucketName="教育帳戶" bucketKey="education" balance={"-"}/>,
					<JarOverviewRow key="NECESSITIES" bucketName="生活帳戶" bucketKey="necessities" balance={"-"}/>,
					<JarOverviewRow key="PLAY" bucketName="玩樂帳戶" bucketKey="play" balance={"-"}/>,
					<JarOverviewRow key="LONGTERM" bucketName="長期帳戶" bucketKey="longterm" balance={"-"}/>,
					<JarOverviewRow key="GIVE" bucketName="贈與帳戶" bucketKey="give" balance={"-"}/>
				]}
			</div>
		</section>
	);
}
