import React from 'react';
import "./HistoryRow.scss"
import { formatRelative, differenceInCalendarDays} from 'date-fns'
import zhTW from 'date-fns/locale/zh-TW'
import BucketDot from "components/BucketDot"
import MoneySpan from "components/MoneySpan"

// https://date-fns.org/docs/I18n-Contribution-Guide#formatrelative
// https://github.com/date-fns/date-fns/blob/master/src/locale/en-US/_lib/formatRelative/index.js
// https://github.com/date-fns/date-fns/issues/1218
// https://stackoverflow.com/questions/47244216/how-to-customize-date-fnss-formatrelative
const formatRelativeLocale = {
  lastWeek: "'上'eeee MM/dd",
  yesterday: "'昨天' MM/dd",
  today: "'今天' MM/dd",
  tomorrow: "'明天' MM/dd",
  nextWeek: "'下' eeee MM/dd",
  other: 'yyyy/MM/dd',
};

const locale = {
  ...zhTW,
  formatRelative: (token) => formatRelativeLocale[token],
};

export default function (props) {
	let diffDays = differenceInCalendarDays(new Date(), props.eventTime)
	let {income, expense, eventTime} = props

	return (
		<div className="history-row date-row">
			<div className="indicator">
				<div className="vertical-line top"></div>
				<BucketDot />
				<div className="vertical-line bottom"></div>
			</div>
			<div className="main-text has-text-grey is-size-7 has-text-weight-bold">
				<div className="">
					{formatRelative(eventTime, new Date(), {locale: locale})}
				</div>
			</div>
			<div className="balance is-flex">
				(
				{income>0 &&
					<MoneySpan
						className="value is-size-6 income"
						value={income}
						showPlus={true}
					/>
				}
				{income>0 && expense<0 && "/"}
				{expense<0 &&
					<MoneySpan
						className="value is-size-6 expense"
						value={expense}
						showPlus={true}
					/>
				}
				)
			</div>
		</div>
	);
}
