import React, {useState} from 'react';
import "./index.scss"

import BucketDot from "components/BucketDot"
import MoneySpan from "components/MoneySpan"
import useLongPress from 'components/useLongPress';

export default (props) => {
	const {bucket, className, totalPrice, onChange} = props
	const [ratio, setRatio] = useState(bucket.percentOfIncome)

	const increasePress = useLongPress(() => {changeRatioTo(ratio+1)}, 150)
	const decreasePress = useLongPress(() => {changeRatioTo(ratio-1)}, 150)

	const changeRatioTo = (ratio) => {
		let v = parseInt(ratio, 0)
		if (v>100) {v = 100}
		if (v<0) {v = 0}
		if (isNaN(v)) {v=0}

		setRatio(v)

		if (onChange) {
			onChange(v)
		}
	}

	return (<div className={"jar-distribute-row " + className}>
		<div className="row-1 is-flex">
			<div className="dot-wrapper is-flex">
				<BucketDot bucketKey={bucket._key}/>
			</div>

			<h3 className="bucket-name">
				{bucket.name}
				&nbsp;
				<MoneySpan value={Math.round(totalPrice*ratio/100)} showPlus={true}/>
			</h3>

			<div className="control">

				<div className="number-part">
					<i className="fas fa-minus-circle"
						{...decreasePress}
						onClick={(e) => {
							changeRatioTo(ratio-1)
						}}/>
					<input
						className="input-number-part"
						type="number"
						value={ratio}
						onChange={(e) => {
							changeRatioTo(e.target.value)
						}}/>
					<i className="fas fa-plus-circle"
						{...increasePress}
						onClick={(e) => {
							changeRatioTo(ratio+1)
						}} />
				</div>

				<span className="percent">%</span>
			</div>
		</div>
	</div>)
}