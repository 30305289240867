import React, {useState, useEffect} from 'react';
import cx from "classnames"

import useStore from "./useStore"
import "./index.scss"

import JarDistributeRow from "./JarDistributeRow"

import LoadingScreen from 'components/LoadingScreen'
import MoneySpan from "components/MoneySpan"
import BucketDot from "components/BucketDot"

import {
	useParams,
	useRouteMatch,
	Link
} from "react-router-dom";

export default function () {
	let params = useParams();
	const statisticPagePath = `/users/${params.userId}/${params.cipher}`

	const {
		defaultBuckets,
		lastUsedBuckets,
		submitIncomeAndBucketRatios,
		loading, userId, errorMsg} = useStore()

	// fetch the item to add
	let searchParams = new URLSearchParams(window.location.search)
	const itemName = searchParams.get("itemName")
	const localPrice = parseInt(searchParams.get("localPrice"), 10)

	// load the previous setting
	const [localBuckets, setLocalBuckets] = useState([])
	useEffect(() => {
		if (localBuckets.length===0 && lastUsedBuckets) {
			setLocalBuckets(lastUsedBuckets)
		}
	}, [lastUsedBuckets])

	// calc the total percents of current setting
	const sumOfLocalBuckets = localBuckets.reduce((acc, b) => {
		acc += b.percentOfIncome
		return acc
	}, 0)

	// create the submit function
	const [submitSucc, setSubmitSucc] = useState(null)
	const [errMessage, setErrMessage] = useState(null)
	const handleSubmit = () => {
		setErrMessage(null)

		submitIncomeAndBucketRatios(Object.fromEntries(searchParams), localBuckets)
		.then(response => {
			if (response.status==="OK") {
				setSubmitSucc(true)
				setTimeout(() => {
					window.location = statisticPagePath
				}, 1000*3)
			}
		})
		.catch(error => {
			setErrMessage(error.toString())
		})
	}

	return (
		<div className="distribute-page">
			<div className="container">
				<h1 className="title is-3">手動分配收入比例</h1>
				<h2 className="subject-part">
					<span className="item-name">
						{itemName}
					</span>
					&nbsp;
					<MoneySpan
						className=""
						value={localPrice}
						showPlus={true}
					/>
				</h2>

				<hr/>

				<div className="list-of-jars">
					{localBuckets && localBuckets.map((b,idx) => {
						return (<JarDistributeRow
							key={b._id}
							className=""
							bucket={b}
							totalPrice={localPrice}
							onChange={(newRatio) => {
								let newBuckets = [...localBuckets]
								newBuckets[idx].percentOfIncome = newRatio
								setLocalBuckets(newBuckets)
							}}/>)
					})}
				</div>

				<hr/>

				<div className="jar-distribute-row sum-row">
					<div className="row-1 is-flex">
						<div className="dot-wrapper is-flex">
							<BucketDot bucketKey={"GENERAL"}/>
						</div>

						<h3 className="bucket-name">
							總計 &nbsp;
							<MoneySpan value={localPrice} showPlus={true}/>
						</h3>

						<div className={cx("control ", {
							failed: sumOfLocalBuckets!==100
						})}>

							<div className="number-part">
								{sumOfLocalBuckets===100 && <i className="fas fa-check-circle"></i>}
								{sumOfLocalBuckets!==100 && <i className="fas fa-exclamation-triangle"></i>}

								<span className="input-number-part">
									{sumOfLocalBuckets}
								</span>
							</div>

							<span className="percent">%</span>
						</div>
					</div>
				</div>

				{!submitSucc &&<div className="buttons buttons-control">
					<button
						className={cx("button is-primary", {"is-loading": loading})}
						disabled={sumOfLocalBuckets!==100}
						onClick={handleSubmit}>送出</button>
				</div>}

				{submitSucc && <div className="submit-succ">
					<article className="message is-success">
						<div className="message-body">
							<p>
									<i className="far fa-thumbs-up"></i> 儲存完成！您可以放心關閉這個視窗。
							</p>

							<p>
								三秒後將自動跳轉到
								<Link
									to={{
										pathname: statisticPagePath
									}}
								>
								統計頁面~
								</Link>
							</p>
						</div>
					</article>
				</div>}

				{errMessage && <div className="submit-succ">
					<article className="message is-danger">
						<div className="message-body">
							<p>
									<i className="fas fa-exclamation-circle"></i> {errMessage}！
							</p>
						</div>
					</article>
				</div>}
			</div>

			<LoadingScreen loading={loading} />
		</div>
	);
}