import { useState, useEffect } from 'react';
import {format, startOfMonth, endOfMonth} from 'date-fns'
import useAuthHeader from 'components/useAuthHeader'

export default function useStore() {
	const [userId, authHeader] = useAuthHeader()

	const [loading, setLoading] = useState(true);
	const [errorMsg, setErrorMsg] = useState()

	const [bucketBalances, setBucketBalances] = useState()
	const [recentTransactions, setRecentTransactions] = useState()

	const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), "yyyy-MM-dd"))
	const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), "yyyy-MM-dd"))

	const fetchTransNBuckets = () => {
		setLoading(true)

		if ( !userId) {
			return
		}

		let q = new URLSearchParams({
			startDate: startDate,
			endDate: endDate
		})


		fetch("/api/home?"+q, {
			headers: Object.assign({}, authHeader),
		})
			.then(response => response.json())
			.then(response => {
				setLoading(false)

				if (response.status==="OK") {
					setBucketBalances(response.bucketBalances)
					// console.log("setBucketBalances", (response.bucketBalances))
					setRecentTransactions(response.transactions)
				} else {
					throw new Error("Server error")
				}
			})
			.catch(() => {
				setLoading(false)
				setErrorMsg("Auth Failed Or Server error")
			})
	}

	useEffect(fetchTransNBuckets, [userId, startDate])

	const deleteTransaction = (transactionId) => {
		// find the local record and delete it
		let newRecentTransactions = recentTransactions.filter(t=>t._key!==transactionId)
		setRecentTransactions(newRecentTransactions)

		// delete from server side
		return fetch("/api/transactions/"+transactionId, {
				method: 'DELETE',
				headers: Object.assign({
					'Content-Type': 'application/json'
				}, authHeader),
				body: JSON.stringify({})
			})
			.then(response => response.json())
			.then(response => {
				console.log('api/transactions', response)
				fetchTransNBuckets() // reload
			})
			.catch(error => {
				console.error('api/transactions', error)
			})
	}


	return {loading, userId, bucketBalances, recentTransactions, errorMsg,
		startDate, endDate, setStartDate, setEndDate, deleteTransaction};
}
